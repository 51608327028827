@import "../../../../styles/helper.scss";

.myDocuments{
    .rmdp-year-picker{
        @include dark{
            background-color: $n82 !important
        }
    }
    .rmdp-arrow-container{
        background: $n7;
        @include dark{
            background-color: $n35;
        }
        height: 32px;
        width: 32px;
        align-items: center;
        &:hover{
            background: $n7;
            color: #000;
            @include dark{
                background-color: $n35;
                color: $white;
            }
        }
    }
    .rmdp-day span{
        border-radius: 4px;
        border: 1px solid $n81;
        @include dark{
            border: 1px solid $n35;
            color: $white;
        }
    }
    .rmdp-day.rmdp-selected span:not(.highlight) {
        background: $n74;
    }
    .rmdp-day.rmdp-today span {
        background: none;
        color: #000;
        @include dark{
            color: $white;
        }
    }
    .rmdp-arrow {
        margin-top: 0px;
    }
    .rmdp-header-values {
        @include dark{
            color: $white;
        }
    }
}