@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&display=swap)

// media queries
=w
    @media only screen and (max-width: 1419px)
        @content

=x
    @media only screen and (max-width: 1339px)
        @content

=d
    @media only screen and (max-width: 1179px)
        @content

=dtt
    @media only screen and (max-width: 1024px)
        @content


=t
    @media only screen and (max-width: 1023px)
        @content

=m
    @media only screen and (max-width: 767px)
        @content

=a
    @media only screen and (max-width: 639px)
        @content

=s
    @media only screen and (max-width: 474px)
        @content

// media queries for mobile-first approach
=ds
    @media only screen and (min-width: 475px)
        @content

=da
    @media only screen and (min-width: 640px)
        @content

=dm
    @media only screen and (min-width: 768px)
        @content

=dt
    @media only screen and (min-width: 1024px)
        @content

=dd
    @media only screen and (min-width: 1180px)
        @content

=dx
    @media only screen and (min-width: 1340px)
        @content

=dw
    @media only screen and (min-width: 1420px)
        @content
    
// fonts
=dm-sans
    font-family: 'DM Sans', sans-serif

=poppins
    font-family: 'Poppins', sans-serif

// colors
$p1: #3772FF
$p1_12: #3579DE1F
$blue: #3C65D6
$blue6per: #3C65D60F
$p2: #9757D7
$p3: #FF6838
$p3_6: #F553530F
$p4: #58BD7D
$s1: #4BC9F0
$s2: #E4D7CF
$s3: #FFD166
$s4: #CDB4DB
$s5: #F5AF45
$s6: #F5BA45
$s7: #F5C4620F
$s8: #f54ca5
$white: #ffffff
$dark: #17181b

$n1: #141416
$n2: #23262F
$n3: #353945
$n4: #777E90
$n5: #B1B5C3
$n6: #E6E8EC
$n7: #F4F5F6
$n8: #FCFCFD
$n9: #B8B8B8
$n10: #F5C462
$n11: #878C99
$n12: #36383D
$n13: #33B87A
$n14: #E6EAF5
$n15: #A2A7B8
$n16: #BCC3D6
$n17: #1B1C1F
$n18: #525254
$n19: #282A2E
$n20: #F1F2F4
$n21: #1f1f1f
$n22: #747e99
$n22_1: #F0F4FF
$n23: #464c5c
$n24: #f55353
$n24_12: #F553531F
$n25: #8b45f5
$n26: #D9D9D9
$n27: #b5b5b5
$n28: #4574f5
$n29: #d32222
$n29_5: rgba(211, 34, 34, 0.5)
$n30:#5C5C5C
$n31:#18191D
$n32:#ebeef5 
$n33:#5D657A1F   
$n34:#43464D  
$n35:#FFFFFF1F   
$n36:#FFFFFF0F 
$n37:#515767 
$n38:#6C7489  
$n39:#f4f5f5 
$n40:#458bf5  
$n41:#7c8398
$n42:#363a45  
$n43:#51545c80 
$n44:#F5AF451A 
$n45:#5D657A
$n46:#6C707A   
$n47:#747E99   
$n48:#f5af45  
$n49:#e6e8ec 
$n50:#222326    
$n51:#23262F    
$n52:#EFB6430F  
$n53:#E88A2C
$n53_6: rgba(226, 122, 19, 0.06)
$n54:#D7DFF5  
$n55:#DFE3EC 
$n56:#D322220F    
$n57:#F3F3F4   
$n58:#2F323D                                      
$n59:#8C97B8  
$n60:#464C5C  
$n61:#35373B  
$n62:#4573F5 
$n63:#7A7A7A
$n64:#F5F5F5 
$n65:#CA16161F 
$n66:#ffecec 
$n67:#e6eaf5                 
$n68:#999999
$n69:#EF9943
$n70:#ED89271F
$n71:#3A3B41
$n72:#2B2D31
$n73:#14B855
$n74:#1472FF
$n75:#1472FF1F
$n76:#E93535
$n77:#EB4747
$n78:#000000
$n79:#EF43430F
$n80:#AFB6C0
$n81: #CCD2E0
$n82: #0E0F10





$youtube: #ED1D24
$spotify: #1ED760
$soundcloud: #F26F23
$lightRed: #D916161F
$lightGreen: rgba(51, 184, 122, 0.53)

//newcolor

// typography
=body-1
    font-size: 24px
    line-height: 1.3
    letter-spacing: -.01em

=body-bold-1
    font-size: 24px
    line-height: 1.3
    font-weight: 600
    letter-spacing: -.01em

=body-2
    font-size: 16px
    line-height: 1.5

=body-bold-2
    font-size: 16px
    line-height: 1.5
    font-weight: 500

=caption-1
    font-size: 14px
    line-height: 1.7

=caption-bold-1
    font-size: 14px
    line-height: 1.7
    font-weight: 500

=caption-2
    font-size: 12px
    line-height: 1.65

=caption-bold-2
    font-size: 12px
    line-height: 1.65
    font-weight: 600

=caption-3
    font-size: 10px
    line-height: 1.6

=hairline-1
    font-size: 16px
    line-height: 1
    font-weight: 700
    text-transform: uppercase

=hairline-2
    font-size: 12px
    line-height: 1
    font-weight: 700
    text-transform: uppercase

=button-1
    +dm-sans
    font-size: 16px
    font-weight: 700
    line-height: 1

=button-2
    +poppins
    font-size: 14px
    font-weight: 500

=button-3
    +poppins
    font-size: 12px
    font-weight: 400

=scroll
    &::-webkit-scrollbar
        width: 3px
        height: 3px
    &::-webkit-scrollbar-track
        border-radius: 10px

    /* Handle */
    &::-webkit-scrollbar-thumb
        background: #777E90
        border-radius: 10px
        +dark
            background: #363a45

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover
        background: #777E90
        +dark
            background: #363a45

//triangle
=arr($width, $height, $bg, $direction)
    width: 0px
    height: 0px
    border-style: solid
    @if $direction == t
        border-width: 0 $width / 2 + px $height + px $width / 2 + px
        border-color: transparent transparent $bg transparent
    @if $direction == r
        border-width: $height / 2 + px 0 $height / 2 + px $width + px
        border-color: transparent transparent transparent $bg
    @if $direction == b
        border-width: $height + px $width / 2 + px 0 $width / 2 + px
        border-color: $bg transparent transparent transparent
    @if $direction == l
        border-width: $height / 2 + px $width + px $height / 2 + px 0
        border-color: transparent $bg transparent transparent
    @if $direction == tl
        border-width: $height + px $width + px 0 0
        border-color: $bg transparent transparent transparent
    @if $direction == tr
        border-width: 0 $width + px $height + px 0
        border-color: transparent $bg transparent transparent
    @if $direction == br
        border-width: 0 0 $height + px $width + px
        border-color: transparent transparent $bg transparent
    @if $direction == bl
        border-width: $width + px 0 0 $height + px
        border-color: transparent transparent transparent $bg
// example
// +arr(500,50, #000, l)
// +arr(500,50, #000, bl)

// placeholder
=placeholder
    &::placeholder
        @content

// media query width
=r($width)
    @media only screen and (max-width: $width+ "px")
        @content

=rmin($width)
    @media only screen and (min-width: $width+ "px")
        @content

=text-overflow
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap

// theme
=dark
    @at-root :global(.dark-mode) &
        @content

=dark-body
    @at-root .dark-mode
        @content

=dark-common
    @at-root .dark-mode &
        @content
