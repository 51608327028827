@import "../../styles/helper.scss";

.exchange-currency-col-overlay {

  @include t {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 20;
    left: -100%;
    box-shadow: 0 0 16px 4px $n5;
    transition: left .2s ease;

    &.active {
      left: 0;
    }
    &.deactive {
      left: -100%;
    }
    @include dark {
      box-shadow: 0 0 16px 4px $n78;
    }
  }

  & > div {
    display: flex;
    flex: 1 1;
    overflow-y: auto;
  }
}
@media (max-width: 480px) {
  .exchange-currency-col-overlay{
    max-width: 100vw !important;
    z-index: 99;
  }
}