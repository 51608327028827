@import styles/helpers

.report_table
    min-height: 158px
    max-height: calc(100vh - 510px)
    overflow: auto
    flex-grow: 1
    table
        flex: 1 1
        height: 100%
    th, td
        white-space: nowrap
        padding-inline: 10px
        vertical-align: middle
        text-transform: capitalize
    +t
        max-height: 256px !important   
    +scroll
    &:hover
        box-shadow: 0 1px 4px rgb(0 0 0 / 16%)
        transition: all .2s ease-in-out

.reportScroll
    height: 100% !important
    overflow: unset !important
  
.head
    background: #EDF1FC
    height: 52px
    color: #747E99
    font-weight: 600
    font-size: 14px
    text-align: left
    position: sticky
    z-index: 1
    top: -2px
    +dark
        background:  #282A2E
        color: $white    
.no_result
    position: static
  
.header
    height: 52px
    display: flex
    align-items: center
    padding-left: 16px

.row
    font-weight: 500
    height: 52px
    background: $white
    border-bottom: 1px solid #e6e8ec
    +dark
        background: $n12
        border-bottom: 1px solid #23262F

.asset
    display: flex
    align-items: center
    gap: 8px
    padding: 0px 0px 4px 16px
    height: 52px

    &_symbol
        width: 32px
        height: 32px
        border-radius: 50%
    &_type
        text-align: left
        font-weight: 500
        font-size: 14px
        line-height: 20px
        width: 150px
    &_name
        text-align: left
        font-weight: 500
        font-size: 12px
        min-width: 50px
        line-height: 16px
        +dark
            color: $n11
    &_arrow
        display: flex
        align-items: center

.assetSymbol
    font-size: 14px
    font-weight: 500
    line-height: 20px

.assetName
    font-size: 12px
    font-weight: 500
    line-height: 16px
    color: #7A7A7A
    +dark
        color: #999999

.transactionType
    display: flex
    gap: 4px
    flex-flow: row nowrap
    align-items: center
    .circle
        width: 8px
        height: 8px
        border-radius: 25px
    .red_circle
        background: #DC1818
    .green_circle
        background: #259351
    .transactionData
        text-transform: capitalize
  
.profit_up
    color:  #00B929
    font-size: 18px

.loss_down
    color: #F55353
    font-size: 18px

.download
    color: #747E99
    font-size: 20px
    padding-left: 24px
    padding-top: 10px
    cursor: not-allowed
    +dark
        color: $white
    &:hover
        color: #bdbec2

.empty_illustration
    padding: 0 !important
    background: $white
    +dark
        background: #36383D
.illustration
    width: 100%
    background: $white
    display: flex
    justify-content: center
    align-items: center
    flex-grow: 1
    +dark
        background: #36383D

.assetLetter
    height: 32px
    width: 32px  
    border-radius:50%   
    background:#EDF1FC
    padding:4px 12px
    color:white     
    +dark
        background: #282A2E

.assetname
    padding: 10px 10px 10px 20px 
    width: 210px

.assetQty
    width: 100px    

