@import styles/helpers

.myDocumentsNavigation
    display: flex
    border-bottom: 1px solid #CCD2E0
    +dark
        border-bottom: 1px solid #222
    &__tab
        font-weight: 500
        font-size: 14px
        line-height: 24px
        cursor: pointer
        color: #999999
        padding: 8px 16px
        border-bottom: 1px solid $n35
        &__active
            color: $n69
            border-bottom: 1px solid $n69
