@import styles/helpers
.myDocuments__container
    width: 100%

.myDocumentsSection
    display: flex
    justify-content: space-between
    align-items: center
    margin-top: 24px
    margin-left: 16px
    &__title
        font-weight: 500
        font-size: 16px
        line-height: 24px
    &__datePicker
        width: 120px
        height: 40px
        border-radius: 4px
        display: flex
        align-items: center
        background: $n7
        color: rgb(135, 140, 153)
        border: 1px solid rgb(230, 232, 236)
        +dark
            color: $white
            border: 1px solid $n35
            background: #FFFFFF08
        &__year
            width: 72px
            height: 40px
            font-weight: 500
            font-size: 16px
            line-height: 24px
            letter-spacing: 0px
            display: flex
            align-items: center
            justify-content: center
            border-right: 1px solid rgb(230, 232, 236)
            +dark
                border-right: 1px solid $n35

        &__calendar
            display: flex
            align-items: center
            justify-content: center
            width: 48px
            height: 40px
            &__icon
                font-size: 24px
                cursor: pointer

.myDocumentsSection__datePickerInput
    display: none
.myDocumentsDatePicker
    background-color: $white
    border: 1px solid #ccc
    +dark
        color: $white !important

.myDocuments__darkMode
    background-color: $n82 !important
    color: $white !important
    border: 1px solid #444 !important


.myDocuments__lightMode
    background-color: #fff !important
    color: #000 !important

