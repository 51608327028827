@import "../../../../styles/helper.scss";

.ReactModal__Overlay,
.ReactModal__Overlay--after-open {
  background-color: rgba(0, 0, 0, 0.5) !important;
  -webkit-overflow-scrolling: touch;
}

.wallet-modal {
  width: 768px;
  // width: fit-content;
  // height: fit-content;

  &:has(.withdraw-confirmation-panel){
    width: 520px;
    .close-btn{
      display: none;
    }
  }

  .Modal__wrapper {
    padding: 0px;
  }
  
  &-container {
    background: #ffffff;
    position: relative;
    @include dark {
      background: #282a2e;
    }
    .confirmation_confirmationHeader__0s8A7{
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
    }

    .close-btn {
      font-size: 24px;
      position: absolute;
      right: 15px;
      top: 5px;
      cursor: pointer;
    }

    &-header {
      padding: 24px 24px 16px 24px;
      border-bottom: 1px solid $n49;
      @include dark {
        border-bottom: 1px solid $n12;
      }
      .heading {
        font-size: 20px;
        font-weight: 600;
        line-height: 28px;
        color: #000000;
      }
      .sub-heading {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: #000000;
      }
    }
  }
 
  .wallet-container-body {
    display: flex;
    flex-direction: column;
    min-height: 208px;
  }

  .wallet-modal-footer {
    display: flex;
    justify-content: flex-end;
    padding: 16px 24px;
    border-top: 1px solid #e6e8ec;
    gap: 8px;
    @include dark {
    border-top: 1px solid #36383d;      
    }

    .btn-primary {
      background:#4574f5;
      color: $white;
      &:hover {
        background-color: var(--color-primary-light);
      }
    }

    .btn-secondary {
      background: rgba(0, 0, 0, 0.12);
      color: $dark;
      &:hover {
        background: rgba(0, 0, 0, 0.3);
      }
      @include dark {
        background: rgba(255, 255, 255, 0.12);
        color: #e6eaf5;
        &:hover {
          background: rgba(255, 255, 255, 0.3);
        }
      }
    }

    button {
      padding: 12px 24px;
      border-radius: 8px;
      font-weight: 500;
      text-transform: capitalize;
      width: 119px;
      max-width: 119px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
    }
  }

  @include dark {
    .heading,
    .sub-heading {
      color: #F5F5F5;
    }
  }

  .form-container {
    .wallet-form-100 {
      display: grid;
      grid-template-columns: repeat(1, minmax(0, 1fr));
      grid-column-gap: 16px;
      row-gap: 16px;
    }

    .input__text-fields {
      background: transparent;
      border: 2px solid $n6;
      &:focus-within {
        border-color: $dark;
        transition: 0.2s ease-in all;
      }
    }

    .Dropdown_label__eVaEB {
      color: #747e99 !important;
      text-transform: lowercase;
      &::first-letter {
        text-transform: capitalize;
      }
    }

    .input__label {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #747e99 !important;

      &::first-letter {
        text-transform: capitalize !important;
      }
    }

    textarea {
      background: transparent;
      border: 2px solid $n6;
      &:focus {
        border: 2px solid $dark;
        transition: 0.2s ease-in all;
      }
    }

    .TextArea_label__ewxUT {
      text-transform: capitalize;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #747e99 !important;
    }

    @include dark {
      .input__text-fields,
      textarea {
        border-color: $n18;
        background-color: transparent;

        &:focus-within {
          border-color: $white;
        }
      }
      .input__fields {
        color: $white;
        caret-color: $white;
      }

      // .TextArea_label__ewxUT {
      //   color: $white;
      // }

      // .input__label {
      //   color: $white !important;
      // }
    }

    .wallet-form-50 {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-column-gap: 16px;
      row-gap: 16px;
    }

    .grid-width-100 {
      grid-column: 1 / span 2;
    }
  }
}

.info-div {
  padding-bottom: 24px;
  border-bottom: 1px dotted #51545c;
  margin-bottom: 16px;

  div {
    width: 100%;
    padding: 16px 12px;
    border-radius: 8px;
    background-color: $n7;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include dark {
      background-color: $n12;
    }

    .heading {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      color: $n15;
      text-transform: capitalize;
    }

    .details {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      color: $n2;
      @include dark {
        color: $white;
      }
    }
  }
}

.disabledBtn {
  cursor: not-allowed;
  opacity: 50% !important;
}
.successModal{
  width: auto !important;
  padding: 0px;
}

@media (max-width: 480px) {
  .wallet-modal{
    width: 100vw;
  }
  .successModal{
    padding: 10px;
  }
  .wallet-modal-container-header{
    padding: 16px;
  }
}