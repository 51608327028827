@import "../../../styles/helpers.sass";
@import "../../../styles/helper.scss";

.order-assets-container{
  overflow-y: auto;
  padding: 10px;
  flex: 1 1;
  .order-asset{
    padding: 12px 0;
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-between;
    cursor: pointer;
    margin-top: 8px;
    background-color: $white;
    border: 1px solid $n6;
    padding: 16px;
    border-radius: 8px;
    @include dark {
      background-color: $n19;
      border: 1px solid $n12;
    }
    &:hover{
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      transition: .4s all ease-in-out;
    }
    &:last-child {
      margin-bottom: 0px;
    }
  }
}

.order-lockup{
  display: flex;
  font-size: 14px;
  font-weight: 500;
  flex-direction: row nowrap;
  align-items: center;
  color: $n15;
  margin-top: -5px;

  .lockInCount {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    padding: 0 8px;
    gap: 4px;
    
    .countCircle {
      width: 16px !important;
   
      .CircularProgressbar-path {
        stroke: #58BD7D;
        stroke-linecap: butt;
      }
    }

    .timeColor{
      line-height: 16px;
    }
  }
}


.order-empty-container{
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
gap: 8px;
}
.order-empty-text{
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  color: $n5;
  @include dark{
    color: $n5
  }
        
}
.no-data-image {
  display: block;
  margin: 100px auto 0;
  width: 150px;
}
.order-assetNameSection{
  display: flex;
  flex-direction: column;
  gap: 4px;
  &__assetInfo{
    display: flex;
    gap: 4px;
    align-items: center;
  }
  &__assetName{
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    @include dark{
      color: $n14;
    }

  }
  &__issuerName{
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    color:var(--color-gray)     
  }
  
 }

 .order-assetDetails-left{
  display: flex;
  flex-direction: column;
  gap: 8px;
  .order-assetDetails{
    display: flex;
    //justify-content: center;
    align-items: center;
    gap: 12px;
    &__logoContainer{
      width: 48px;
      height:48px;
      padding: 4px;
      border-radius: 24px;
      gap: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: $n6;
      @include dark{
        background: $n12;
      }
      
     &__assetLogo{
      height: 48px;
      width: 48px;
      border-radius: 24px;
     }

     
    }
  }
}
.order-assetQty{
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  color: $n46;
  &__qty{
  font-weight: 400;
  color: $n3;
  @include dark{
    color: $n15;
  }
  }
}

.order-time{
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 8px;
  &__time{
    display: flex;
    justify-content: center;
    align-items: center;
    color: $n46;
    gap: 8px;
    span{
      color: $n15;
      font-weight: 500;
    }

  }
  &__date{
    display: flex;
    justify-content: center;
    align-items: center;
    color: $n46;
    gap: 8px;
    span{
      color: $n15;
      font-weight: 500;
    }
  }
}

.order-assetDetails-right{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 12px;
  &__top{
    gap: 4px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  &__amount{
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
  }
  &__total-amount{
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    color: $n15; 
    display: flex;
    justify-content: center;
    gap: 8px;  
    span{
      font-family: Poppins;
      font-weight: 500;
      line-height: 16px;
      color: $n46;
    } 
  }
  &__button-section{
    display: flex;
    gap: 8px;
    &__assetType{
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      width: auto;
      height: 24px;
      padding: 4px 12px;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $n11;
      text-transform: capitalize;
      background: $n6;
      @include dark{
        background: $n46;
        color:$white
      }
    }
    
  }
}
.order-category{
  width: auto;
}
.buyTrade{
  color: $n13;
  @include dark{
    color:$n13 !important
 }
}
.sellTrade{
  color: $n24;
  @include dark{
    color:$n24;
 }

}


.order-details-container{
  padding-top: 24px;
  width: 768px;
  margin: 0 auto; 
  display: flex;
  flex-direction: column;
  flex: 1 1;
  overflow-y: auto;
}

.order-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .order-header-left {
    display: flex;
    gap: 15.64px;
    align-items: center;
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      border-radius: 5px;
      cursor: pointer;
      border: 1px solid $n46;
      i {
        font-size: 24px;
        @include dark {
          color:$white;
        }
      }
    }

    p {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      text-transform: capitalize;
    }
  }
}
.order-details__date{
  color: $n5;
}
.order-details {
  width: 100%;
  display: flex;
  flex-direction: column;

  &__date{
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: var(--color-gray);
    @include dark{
      color: $n5;
    }
  }

  .order-details-main-status-info {
    display: flex;
    flex-direction: column;
    gap: 8px;
    height: 180px;
    align-items: center;
    justify-content: center;

    .status-type {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      color: $n5;
      text-transform: capitalize;
    }

    .amount {
      font-size: 32px;
      font-weight: 600;
      line-height: 48px;
    }


    .order-status {
      display: flex;
      align-items: center;
      gap: 8px;
      margin-top: -4px;

      i.ri {
        font-size: 20px;
      }

      p {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        color: $n13;
        text-transform: capitalize;
      }
    }

    .EXECUTED {
      i,
      p {
        color: $n13;
      }
    }

    .PENDING {
      i,
      p {
        color: $n48;
      }
    }

    .FAILED {
      i,
      p {
        color: $n24;
      }
    }

    .CANCELLED {
      i,
      p {
        color: $n47;
      }
    }
  }

  .order-details-body {
    .order-panel {
      display: flex;
      align-items: center;
      padding: 8px 16px;
      justify-content: space-between;
      border-bottom: 1px solid $n5;
      @include dark{
        border-bottom: 1px solid $n3;
      }

      small {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: $n11;
      }

      p {
        text-align: end;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        text-transform: capitalize;
      }
    }
  }

  .transaction-status-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 32px;

    .status-btn {
      width: 288px;
      padding: 16px 24px;
      border-radius: 8px;
      transition: all 0.25s;
      text-transform: capitalize;

      &:hover {
        opacity: 70%;
      }
    }

    button.PROCESSING {
      background-color: $s5;
      color: $n24;
    }

    button.FAILED {
      background-color: $n22;
      color: $white;
    }
  }
}
.transactionHash {
  max-width: 200px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.transactionHashIcon {
  margin-left: 8px;
}

.order-list-bullet{
  width: 4px;
  height: 4px;
  border-radius: 4px;
  background:$n11;                    
  margin-bottom: 2px;                   
  display: inline-block;             
}

.order-cancelled{
  margin-left: 8px;
  &__status{
    padding-left: 8px;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    color: $n24;
  }
}
.mlc_order-assets-container {
  @extend .order-assets-container
}
.mlc__order-assetDetails-right__button-section__assetType {
  @extend .order-assetDetails-right__button-section__assetType;
}
.mlc_order-details-container{
  @extend .order-details-container
}

@media (max-width: 480px) {
  .mlc_order-assets-container {
    padding: 0;
    padding-bottom: 90px;
    .order-asset {
      background-color: transparent;
      border: none;
      border-bottom: 1px solid #E6E8EC;
      border-radius: 0;
      @include dark {
        background-color: transparent;
        border: none;
        border-bottom: 1px solid #525254;

      }
    }
  }
  .mlc__order-assetDetails-right__button-section__assetType{
    padding: 4px 8px;
  }
  .mlc_order-details-container{
    width: 100vw;
    padding: 16px;
    padding-bottom: 90px;
  }
}