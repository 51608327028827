.container-body {
  // padding: 24px;
  padding: 20px 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  flex: 1 1 ;
  gap: 20px;
}
.mlc_container-body{
  @extend .container-body
}
@media (max-width: 480px) {
  .mlc_container-body{
    padding-bottom: 0;
  }
}
