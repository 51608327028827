@import "../../../../styles/helper";

.all-portfolio-container {
  flex: 1 1;

  .portfolio-cart-list-container {
    display: grid;
    align-items: center;
    gap: 10px;
    padding: 0 20px 20px;
    margin-block: 20px 10px;
    background-color: $white;
    border-radius: 8px;
    border: 1px solid $n6;

    .portfolio-card-list-wrapper{
      display: flex; 
      flex-direction: column; 
      overflow: hidden 
    }

    .portfolio-chart-image{
      display: flex;
      justify-content: center;
      align-items: center;
      &__no-data-image{
        width: 150px;
        margin: 95px 0;
      }
    }
   

    .variable-pie-chart-container {
      height: 550px;
    }

    .current-and-portfolio {
      line-height: normal;
      margin-bottom: 15px;
      padding: 0 60px 10px;
      display: inherit;
      justify-content: space-around;
      border-bottom: 1px solid $n6;

    }

    .portfolio-assets {
      display: grid;
      grid-template-columns: 4fr 4fr 4fr;

      .portfolio-asset {
        display: grid;
        grid-template-columns: auto auto;
        justify-content: space-between;
        align-items: center;
        position: relative;
        padding-block: 5px;

        // &:nth-child(2n+1):after {
        //   content: "";
        //   display: inline-block;
        //   position: absolute;
        //   top: 0;
        //   bottom: 0;
        //   left: 100%;
        //   transform: translate(30px, 0);
        //   border-right: 1px solid $n6;
        // }
      }

      .asset-detail {
        padding-left: 10px;
        border-left: 4px solid #10BC55;
      }

      .asset-detail_value {
        font-size: 16px;
      }

      .assets-price {
        font-size: 14px;
        font-weight: 600;
      }
    }

    .portfolio-chart-prices {
      display: flex;
      justify-content: space-between;
      // gap: 25px;
      flex-wrap: wrap;

      .asset-detail {
        display: flex;
        gap: 10px;
        align-items: center;
        position: relative;

        // &:first-child:after {
        //   content: "";
        //   display: inline-block;
        //   padding: 3px;
        //   background-color: $n5;
        //   border-radius: 50%;
        //   position: absolute;
        //   left: calc(100% + 12.5px);
        //   top: 50%;
        //   transform: translate(-50%, -50%);
        // }
      }
      .asset-detail_value {
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
      }
    }

    @include dark {
      background-color: $n19;
      border-color: $n12;

      .portfolio-assets .portfolio-asset {
        &:nth-child(2n+1):after {
          border-right-color: $n12;
        }
      }

      .portfolio-chart-prices {
        .asset-detail {
          &:first-child:after {
            background-color: $n18;
          }
        }
      }

      .current-and-portfolio {
        border-bottom-color: $n18;
      }
    }
  }


  .all-assets {
    display: grid;
    gap: 10px;
    padding-block: 15px;
  }

  .assets-container {
    background-color: $white;
    border-radius: 8px;
    padding: 10px;
    border: 1px solid $n6;

    .portfolio-disabled{
      cursor: not-allowed !important;
    }

    .assets-heading {
      display: grid;
      grid-template-columns: auto auto;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      border-bottom: 1px solid $n6;
      padding-bottom: 10px;

      .label {
        display: inline-block;
        border-left: 4px solid $s8;
        font-size: 16px;
        font-weight: 600;
        padding-left: 5px;
        line-height: normal;
        text-transform: capitalize;
      }

      .arrow-icon {
        color: $n11;
        padding-inline: 5px;
        font-size: 24px;
        line-height: normal;
      }
    }

    .asset-details {
      display: grid;
      grid-template-columns: 6fr 6fr;
      align-items: flex-end;
      padding-block: 6px;
      gap: 6px;
    }

    .assets-image {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      margin-left: -10px;
      background-color: $n6;

      &:first-child {
        margin-left: 0;
      }
    }

    .assets-image-len {
      font-size: 8px;
      font-weight: 500;
      min-width: 22px;
      min-height: 18px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      padding: 5px;
      background-color: $n7;
      margin-left: -10px;
    }

    @include dark {
      background-color: $n19;
      border-color: $n12;

      .assets-image {
        background-color: $n18;
      }

      .assets-image-len {
        background-color: $dark;
      }

      .assets-heading {
        border-bottom-color: $n12;
      }
    }
  }

  .asset-detail {
    &_value {
      display: flex;
      font-size: 14px;
      font-weight: 600;
      line-height: 28px;

      .positive {
        color: $n13;
      }

      .negative {
        color: $p3;
      }
      .neutral{
        color: $n11;
      }
    }

    &_label {
      text-transform: capitalize;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: $n11;
      display: flex;
      align-items: center;
      gap: 4px;
    }
    &_InfoIcon{
      font-size: 20px;
      cursor: pointer;
    }
  }

}
.highcharts-tooltip-box {
  fill: $white;

  @include dark {
    fill: $dark;
  }
}

.pie-template {
  fill: $n4;

  .value {
    fill: black;
    text-transform: capitalize;
  }

  @include dark {
    .value {
      fill: $white;
    }
  }
}

.react-tooltip.portfolio-tooltip{
  max-width: 600px !important;
  @include dark{
    background: none;
    background-color:$n19 !important;
  }
}

.mlc_all-portfolio-container{
  @extend .all-portfolio-container
}
.mlc_portfolio-cart-list-container{
  @extend .portfolio-cart-list-container
}
.mlc_variable-pie-chart-container{
  @extend .variable-pie-chart-container
}
.mlc_current-and-portfolio{
  @extend .current-and-portfolio
}
.mlc_portfolio-chart-prices{
  @extend .portfolio-chart-prices
}

@media (max-width: 480px) {
  .mlc_all-portfolio-container {
    padding: 0 16px;

    .mlc_portfolio-cart-list-container {
      margin: 0;
      padding: 0px;
      background-color: transparent;
      border: none;

      @include dark {
        background-color: transparent;
        border: none;
      }
    }
    .assets-container{
      padding: 16px;
      .assets-heading{
        padding-bottom: 8px;
        .label{
          font-size: 14px;
          line-height: 20px;
        }
        .arrow-icon{
          font-size: 20px;
        }
      }
      .asset-details{
        padding-block: 0;
        padding-top: 8px;
      }
    }
    .all-assets{
      padding-bottom: 0;
    }
  }

  .mlc_variable-pie-chart-container {
    height: 200px !important;
  }

  .mlc_current-and-portfolio {
    padding: 0 !important;
    padding-bottom: 8px !important;
    margin-bottom: 8px !important;
    .asset-detail{
      .asset-detail_label{
        font-size: 14px !important;
        line-height: 20px;
        font-weight: 500;
      }
      .asset-detail_value{
        font-size: 20px !important;
        line-height: 28px;
        font-weight: 600;
      }
    }
  }
  .mlc_portfolio-chart-prices{
    .asset-detail{
      .asset-detail_label{
        font-size: 12px;
        line-height: 16px;
        font-weight: 500;
      }
      .asset-detail_value{
        font-size: 12px;
        line-height: 16px;
        font-weight: 600;
      }
    }
  }
}