@import ../../../../styles/helpers

.withdrawHeader,.changeHeader    
    padding: 16px 24px
    border-bottom: 1px solid $n6
    font-weight: 600
    font-size: 16px
    line-height: 24px
    display: flex
    align-items: center
    justify-content: space-between
    +dark
        border-bottom: 1px solid #43464D
.withdrawBody
    padding: 24px
.withdrawFooter
    padding: 12px 24px
    border-top: 1px solid $n6   
    +dark
        border-top: 1px solid #43464D
    .selectBtn
        font-weight: 500
        font-size: 14px
        line-height: 20px
        border-radius: 8px
        border: none    
    .continueWithdraw
        width: 100%
        font-weight: 500
        font-size: 14px
        line-height: 20px
        border-radius: 8px
        border: none
        background: #3772FF
        color: #FCFCFD
        transition: all 0.2s
.label
    margin: 20px 0 8px 
    +hairline-2
    color: $n5
    text-transform: capitalize
.customLabel
    text-transform: capitalize    
.selectedAccount
    display: flex
    background: $n7
    padding: 12px
    border-radius: 8px
    justify-content: space-between
    position: relative
    align-items: center
    +dark
       background: #36383D
    button
        border-radius: 40px
        padding: 4px 8px
        font-weight: 500
        font-size: 12px
        line-height: 16px
        background: $white
        +dark
             background: rgba(255, 255, 255, 0.06)
             color: #BCC3D6   
    img
       margin-right: 16px
       height: 48px
       max-width: 48px
       +dark
.accounDetail
    display: flex
    align-items: center
.accountName    
    font-weight: 600
    font-size: 14px
    line-height: 20px
    color: #23262F
    +dark
         color: $n14
.accountNumber
    font-weight: 500
    font-size: 12px
    line-height: 16px
    color: #878C99
    margin-top: 4px
    display: flex
    align-items: center
    text-transform: capitalize
.accounType
    margin-right: 4px    
.maskDots
    width: 4px
    height: 4px
    display: inline-block
    background: #878C99
    border-radius: 4px
    margin: 0 1px
    &:first-child
        margin-left: 2px

.transactionSuccess
    display: flex
    flex-direction: column
    justify-content: center
    min-height: 450px
    align-items: center
    padding: 0 24px
    .successHead
        font-weight: 600
        font-size: 32px
        line-height: 48px
    .successSubHead
        font-weight: 400
        font-size: 14px
        line-height: 24px
        color: $n3
        margin: 4px 0 16px
        +dark
            color: #BCC3D6
        +s
            font-size: 23px
            margin: 16px 16px 16px 0
        span
            font-weight: 600
            color: $n2
            +dark
                color: #FFFFFF    
    .transactionDetail
        padding: 20px 16px
        background:#F4F5F6
        border-radius: 8px
        width: 100%
        display: flex
        gap:16px
        +dark
            background: #36383D
    .transactionCol
        width: 50%
        font-weight: 400
        font-size: 12px
        line-height: 16px
        color: $n2
        +dark 
            color: #A2A7B8
    .transactionStatus
        font-weight: 500
        font-size: 14px
        line-height: 20px
        color: #33B87A
        margin-top: 4px
    .transactionId
        font-weight: 500
        font-size: 14px
        line-height: 20px
        color: $n2
        margin-top: 4px
        +dark
            color: #FFFFFF

.selectAccountFooter
    text-align: right
    .selectBtn
        max-width: 112px
        width: 100%
        &:first-child
            margin-right: 8px
            background: #f4f5f6
            color:#23262f
            +dark
                background: rgba(255, 255, 255, 0.06)
                color: #D8DBE6                
           
    
    
            

              