@import "../../styles/helper";

.portfolio-wallet-wrapper{
  display: flex;
  gap: 20px;
}
.portfolio-container {
  padding-inline: 10%;
  overflow-y: auto;

  .heading {
    font-size: 24px;
    font-weight: 600;
    margin-block: 15px;
    color: black;
    @include dark {
      color: $white;
    }
  }

  .portfolio-tabs {
    display: flex;
    border-bottom: 1px solid $n6;
    gap: 10px;

    &_item {
      font-size: 14px;
      font-weight: 600;
      color: $n4;
      cursor: pointer;
      padding: 10px 15px;
      border-bottom: 2px solid transparent;
      margin-bottom: -1px;
      text-transform: capitalize;
      &:hover{
        color: $blue;
        border-bottom-color:  $blue;
        transition: .4s all ease-in-out;
        @include dark {
          color: $n10;
          border-bottom-color: $n10;
        }
      } 
      &.active {
        color:  $blue;
        border-bottom-color: $blue;
        @include dark {
          color: $n10;
          border-bottom-color: $n10;
        }
      }
    }

    @include dark {
      border-bottom-color: $n4;
    }
  }
}

.mlc_portfolio-wallet-wrapper{
  @extend .portfolio-wallet-wrapper
}
.mlc_portfolio-container{
  @extend .portfolio-container
}
.mlc_heading{
  @extend .heading
}

@media (max-width: 480px) {
  .mlc_portfolio-wallet-wrapper{
    flex-direction: column;
    gap: 8px;
  }
  .mlc_portfolio-container{
    padding: 0%;
  }
  .mlc_heading{
    display: none !important;
  }
}
