@import "../../../../styles/helpers"

.main_container
    display: flex
    flex-direction: column
    width: 100%
    iframe
        display: block !important
    .termsConditions
        h1
            text-align: center
            +dark
                color: $white
        .termText
            text-align: center
            padding: 16px
        .agreeBtn
            text-align: center
.compliance_iframe
    width: 100%
    height: 90vh
    border: none
    align-self: center

.compliance_container
    height: 100%
    display: flex
    justify-content: center
    flex-direction: column
    align-items: center
    text-align: center
.compliance_info
    width: 400px
    height: 70vh
    display: flex
    justify-content: center
    flex-direction: column
    align-items: center
    text-align: center

.logout_btn
    color: var(--color-text-60-light)
    background-color: $n7
    font-size: 14px
    font-weight: 600
    align-self: center
    border-radius: 8px
    padding: 16px 24px
    width: 164px
    +dark
        background-color: $n36

.proceed_btn
    background-color: var(--color-primary-light)
    color: white
    font-size: 14px
    font-weight: 600
    width: 96%
    max-width: 750px
    align-self: center
    border-radius: 6px
    padding: 16px 24px
    width: 164px

.compliance_heading
    color: black
    font-size: 16px
    font-weight: 600

.compliance_desc
    color: #5D657A
    font-size: 12px
    font-weight: 500
    line-height: 16px

.kyc
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center

.kyc_heading
    margin: 10px 0
    color: var(--color-text-100-light)
    +dark
        color: var(--color-text-100-dark)

.kyc_para
    padding: 0 70px
    text-align: center
    color: var(--color-text-60-light)
    margin-bottom: 10px
    a
        color: var(--color-primary-light)
        text-decoration: underline

.kyc_btnStart
    width: 200px

.kyc_btn
    display: flex
    justify-content: center
    gap: 10px

.buttonIcon
    font-size: 20px

.kyc_userDetail
    margin-bottom: 10px
    border: 1px solid $n55
    border-radius: 4px
    padding: 8px 120px 8px 8px
    background-color: $n7
    font-weight: 500
    +dark
        background-color: $n3
        border: 1px solid $n1


.kyc_userDetailHead
    color: $n37
    +dark
        color: $n4

.kyc_review
    margin: -8px
    .kyc__review
        display: flex
    .kyc_review_list
        width: 60%
        padding-right: 24px
    .kyc_review_progress
        width: 40%
        border-radius: 8px
        border: 1px solid #0000001F
        padding: 16px
        +dark
            border-color: #FFFFFF1F
        h3
            font-size: 14px
            font-weight: 500
            +dark
                color: $n8
            &.heading
                font-weight: 600
        p
            font-size: 12px
            color: $n63
            +dark
                color: var(--color-gray-medium-light)
    .proceed_btn_review, .proceed_btn_review_1, .proceed_btn_review_2
        background-color: #1472FF08
        color: #1472FF
        font-size: 14px
        font-weight: 600
        width: 100%
        // max-width: 750px
        align-self: center
        border-radius: 6px
        padding: 16px 24px
        // width: 164px
        border: 1px solid #1472FF
        margin: 8px 0
        text-align: center
        flex: 1 1
        white-space: nowrap
    .proceed_btn_review_1
        background-color: #1472FF
        color: $white

    .proceed_btn_review_2
        background-color: transparent
        border: 1px solid $n5
        color: $n4
        +dark
            border: 1px solid $n3
            color: $n9
    .kyc_heading_review
        color: var(--color-text-100-light)
        font-weight: 600
        +dark
        color: var(--color-text-100-dark)
    .kyc_para_review
        // padding: 0 70px
        // text-align: center
        color: $n63
        +dark
            color: var(--color-gray-medium-light)
        padding-bottom: 24px
        // margin-bottom: 10px
        a
            color: var(--color-primary-light)
            text-decoration: underline
    .kycRow
        display: flex
        align-items: flex-start
        gap: 12px
        padding: 4px
        border-bottom: 1px solid #0000000F
        +dark
            border-bottom: 1px solid #FFFFFF0F

    .kyc_dot
        background: #ED8927
        width: 20px
        height: 20px
        border-radius: 20px
        margin-top: 4px
        &.complete
            background:  #14B855
        &.reject
            background: $n29
        &.pending
            background: $n68
    .kycRow_head
        font-size: 16px
    .kycRow_status
        font-size: 14px
        color: $n63
        text-transform: capitalize
        +dark
            color: var(--color-gray-medium-light)
    .progress_bar
        display: flex
        gap: 8px
        .kyc_dot
            margin: 0
        &.complte
            .kyc_dot
                background: #14B855
            .progressline
                background: #14B855
        &.reject
            .kyc_dot
                background: $n29
        &.notDone
            .kyc_dot
                border: 2px solid #D6D6D6
                background: none
                +dark
                    border-color: #3A3B41
        &.inProgress
            .kyc_dot
                border: 2px solid #F5AF45
                background: none
            .progressline
                background: #F5AF45
    .progressline
        width: 2px
        background: #D6D6D6
        height: 36px
        margin-left: 9px
        +dark
            background: #3A3B41
        &.complete
            background:  #14B855
    .progressContainer
        padding: 16px 0
        border-bottom: 1px solid #0000000F
        margin-bottom: 16px
        +dark
            border-color: #FFFFFF0F
    .mail
        color: #1472FF
        display: flex
        align-items: center
        gap: 4px
        i
            font-size: 20px
        a
            color: #1472FF

.compliance_iframe_loader
    width: 100%
    height: 90vh
    display: grid
    place-items: center

.kyc_para_rejected
    display: flex
    gap: 16px
    padding: 12px 16px
    border-radius: 4px
    border: 1px solid $n76
    background-color: #E935350F
    font-size: 14px
    margin-bottom: 16px

.kyc_para_rejected_icon
    font-size: 20px
    color: $n76

.btn_group
    display: flex
    gap: 10px

.btn_group_reverse
    flex-direction: column-reverse
    gap: 0

.disbaled_btn
    cursor: not-allowed
    opacity: 0.7



@media ((max-device-width: 480px))
    .kyc_review
        margin: 0
        height: 100%
        display: flex
        flex-direction: column
        justify-content: center
        margin-top: 10px
    .kyc__review
        flex-direction: column
    .kyc_review_list
        width: 100% !important
        padding: 0 !important
    .kyc_review_progress
        width: 100% !important
    .mlc_btn_group
        display: none

@media ((min-device-width: 480px)) 
    .mlc_btn_group_bottom
        display: none
