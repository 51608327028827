@import ../../../../styles/helpers

.withdrawHeader,.changeHeader    
    padding: 16px 24px
    border-bottom: 1px solid $n6
    font-weight: 600
    font-size: 16px
    line-height: 24px
    display: flex
    align-items: center
    justify-content: space-between
    +dark
        border-bottom: 1px solid #43464D
.withdrawHeader
    font-size: 20px
    display: flex 
    flex-direction: column    
    align-items: flex-start  
    gap: 4px 
.withdrawSubHeader
    font-size: 14px
    font-weight: 400
    line-height: 20px
    +dark
        color: $n68
.fundNote
    margin-top: 8px
    font-size: 14px
    font-weight: 500
    line-height: 20px
    +dark
        color: $n9


.withdrawBody
    padding: 16px 24px
.withdrawFooter
    padding: 12px 24px
    border-top: 1px solid $n6   
    +dark
        border-top: 1px solid #43464D
    .selectBtn
        font-weight: 500
        font-size: 14px
        line-height: 20px
        border-radius: 8px
        border: none    
    .continueWithdraw
        width: 100%
        font-weight: 500
        font-size: 14px
        line-height: 20px
        border-radius: 8px
        border: none
        background: #3772FF
        color: #FCFCFD
        transition: all 0.2s
.label
    +hairline-2
    color: $n5
    text-transform: capitalize
    font-weight: 500
.customLabel
    text-transform: capitalize    
.selectedAccount
    display: flex
    background: $n7
    padding: 12px 16px
    border-radius: 8px
    justify-content: space-between
    position: relative
    align-items: center
    cursor: pointer
    +dark
       background: #36383D
    button
        border-radius: 40px
        padding: 4px 8px
        font-weight: 500
        font-size: 12px
        line-height: 16px
        background: $white
        +dark
             background: rgba(255, 255, 255, 0.06)
             color: #BCC3D6   
    img
       margin-right: 16px
       height: 48px
       max-width: 48px
       +dark
.accounDetail
    display: flex
    align-items: center
.accountName    
    font-weight: 600
    font-size: 14px
    line-height: 20px
    color: #23262F
    +dark
         color: $n14
.accountNumber
    font-weight: 500
    font-size: 12px
    line-height: 16px
    color: #878C99
    margin-top: 4px
    display: flex
    align-items: center
    text-transform: capitalize
.accounType
    margin-right: 4px    
.maskDots
    width: 4px
    height: 4px
    display: inline-block
    background: #878C99
    border-radius: 4px
    margin: 0 1px
    &:first-child
        margin-left: 2px

.transactionSuccess
    display: flex
    flex-direction: column
    justify-content: center
    height: 320px
    width: 480px
    align-items: center
    padding: 24px
    +s
        width: 100%
        padding: 16px
        height: auto
    .successHead
        font-weight: 600
        font-size: 32px
        line-height: 48px
    .successSubHead
        font-size: 24px
        font-weight: 600
        line-height: 36px
        color: $n3
        margin: 4px 0 16px
        +dark
            color: $white
        +s
            font-size: 23px
            margin: 16px 16px 16px 0
        
    .successPara
        font-size: 14px
        font-weight: 400
        line-height: 24px
        margin: 4px 0 16px
        text-align: center
        +dark
            color: $n16
        span
            font-weight: 600
            color: $n2
            +dark
                color: #FFFFFF     
            
               
    .transactionDetail
        padding: 20px 16px
        background:#F4F5F6
        border-radius: 8px
        width: 100%
        display: flex
        gap:16px
        +dark
            background: #36383D
    .transactionCol
        width: 50%
        font-weight: 400
        font-size: 12px
        line-height: 16px
        color: $n2
        +dark 
            color: #A2A7B8
    .transactionStatus
        font-weight: 500
        font-size: 14px
        line-height: 20px
        margin-top: 4px
        &__success
            color:$n13
        &__processing
            color: $s5  
        &__failed
            color: $n24    

    .transactionId
        font-weight: 500
        font-size: 14px
        line-height: 20px
        color: $n2
        margin-top: 4px
        +dark
            color: #FFFFFF
    &__footer
        display: flex
        gap: 8px
        align-items: center
        margin-top: 24px
        margin-left: 140px
        +s
            margin-left: auto
        &__cancel
            width: 112px
            height: 52px
            padding: 16px 24px 16px 24px
            border-radius: 8px
            background: rgba(0, 0, 0, 0.12)
            color: $dark
            +dark
                background: rgba(255, 255, 255, 0.12)
                background: $n36
                color:$n67
        &__view     
            width: 170px
            height: 52px
            border-radius: 8px
            background: $blue
            color: $white
       

.selectAccountFooter
    text-align: right
    .selectBtn
        max-width: 112px
        width: 100%
        &:first-child
            margin-right: 8px
            background: #f4f5f6
            color:#23262f
            +dark
                background: rgba(255, 255, 255, 0.06)
                color: #D8DBE6                
.primary
    display: flex
    justify-content: center
    align-items: center
    margin-left: 8px
    width: 56px
    height: 22px
    padding: 4px 8px 
    gap: 8px
    border-radius: 4px
    font-size: 10px
    font-weight: 500
    line-height: 14px
    color: $n69
    background: $n70
           
.withdrawAmount
    height:130px
    padding: 12px
    gap: 4px
    border-radius: 4px 0px 0px 0px
    display: flex
    justify-content: center
    align-items: center
    flex-direction: column
    border: 1px solid $n6
    +dark
        border:1px solid $n35
    &__amountContainer
        display: flex
        justify-content: center
        align-items: center
        width: 100%
    &__dollarSign
        font-size: 40px
        font-weight: 500
        line-height: 56px
    &__amounttodeposit
        color: $n68
        font-size: 14px
        font-weight: 500
        line-height: 20px
    &__amountInput
        height: 56px
        background: transparent
        font-size: 40px
        font-weight: 500
        line-height: 56px
        color: $dark
        +dark
            color: $n64
.AddbankAccountLabel
    display: flex
    justify-content: space-between
    align-items: center
    padding: 24px 0 8px 0

.addNewbank
    display: flex
    min-width: 110px
    justify-content: center
    align-items: center
    padding: 4px 12px 4px 12px
    border-radius: 32px 
    font-size: 12px
    font-weight: 500
    line-height: 16px
    cursor: pointer
    background: $n7
    +dark
        background: $n71
        color: $white

.dropdownIconBody
    margin-right: 16px
    font-size: 20px

.selectbankAccounts
    background: $n7
    border-radius: 4px
    border: 1px solid $n6
    +dark
        border: 1px solid $n64
        background:$n12
.renderedBankAccounts
    padding: 12px 16px  
    display: flex
    flex-direction: column
    gap: 12px
    overflow: auto
    max-height: 210px
    border-top: 1px solid $n35

.allbankAccounts
    display: flex
    flex-direction: column
    gap: 4px 
    &__bankTitle
        display: flex
        gap: 8px
        align-items: flex-start
        &__logo
            width:24px
                height: 24px
        &__details        
            display: flex
            flex-direction: column
            gap: 8px
        &__title    
            font-size: 12px
            font-weight: 500
            line-height: 16px
    &__subAccount
        display: flex
        flex-direction: column
        margin-left: 4px
        gap: 6px
        &__account
            display: flex
            gap: 8px
            &__checkBox
                color: $n68
                cursor: pointer
            &__name
                font-size: 12px
                font-weight: 500
                text-transform: capitalize      
                display: flex
                gap: 8px  
            &__mask
                color: $n9    
                +dark
                    background: $n12

