@import "styles/helper.scss";

.report-chart-container{
  flex: 1;
  min-width: 300px;
  padding: 16px 16px 0 16px;
  border-radius: 4px;
  background-color: rgba(245, 245, 245, 1);
  @include dark{
    background-color: rgba(29, 30, 32, 1);
  }
}

.chart-header-container{
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 8px;
   h1{
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
  }

  .header-subtitle{
    p{
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0.01em;
    }
  }

  .flex-column{
      display: flex;
      width: 100%;
      flex-flow: column nowrap;
  }

  .flex-row{
    display: flex;
    width: 100%;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
  }

  .header-profit-loss{
    display: flex;
    flex-flow: row nowrap;
    gap: 8px;
    justify-content: space-between;
    align-items: center;

    .percentage{
      padding: 0 8px;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      border-radius: 4px;

      &.positive{
        background-color: rgba(51, 184, 122, 0.12); 
      }

      &.negative{
        background-color: rgba(233, 53, 53, 0.12); 
      }
    }
  }
}

.chart-loader{
  height: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
}