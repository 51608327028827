@import "../../../../styles/helper";

.transaction-wrapper {
  padding-inline: 20%;
  padding-top: 24px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  flex: 1 1;
}
.transaction-details-body {
  width: 600px;
}

.transaction-details-main {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .transaction-details-main-status-info {
    display: flex;
    flex-direction: column;
    gap: 8px;
    height: 180px;
    justify-content: center;
    align-items: center;

    .status-type {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      color: $n5;
      text-transform: capitalize;
    }

    .amount {
      font-size: 32px;
      font-weight: 600;
      line-height: 48px;
      text-transform: capitalize;
    }

    .transaction-status {
      display: flex;
      align-items: center;
      gap: 8px;
      margin-top: -4px;

      i.ri {
        font-size: 20px;
      }

      p {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        color: $n13;
        text-transform: capitalize;
      }
    }

    .SUCCESS {
      i,
      p {
        color: $n13;
      }
    }

    .PROCESSING {
      i,
      p {
        color: #f5af45;
      }
    }

    .FAILED ,
    .AUTOCANCELLED {
      i,
      p {
        color: $n24;
      }
    }

    .CANCELLED {
      i,
      p {
        color: $n22;
      }
    }
  }

  .transaction-details-body {
    .transaction-panel {
      border-bottom: 1px solid rgba(54, 56, 61, 1);
      display: flex;
      align-items: center;
      padding: 16px;
      justify-content: space-between;

      small {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: $n11;
        text-transform: capitalize;
        flex-basis: 40%;
        text-align: left;
      }

      span {
        margin: 0 5px;
      }

      p {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        text-align: left;
        text-align: end;
      }
    }
  }

  .transaction-status-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 32px;

    .status-btn {
      width: 288px;
      padding: 16px 24px;
      border-radius: 8px;
      transition: all 0.25s;
      text-transform: capitalize;

      &:hover {
        opacity: 70%;
      }
    }

    button.PROCESSING {
      background-color: rgba(245, 86, 69, 0.15);
      color: $n24;
    }

    button.FAILED {
      background-color: $n22;
      color: $white;
    }
  }
}

.transaction-details{ width: 100%;
display: flex;
flex-direction: column;
justify-content: center;
}
.transactionHash {
  display: block;
  max-width: 200px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.transactionHashIcon {
  margin-left: 8px;
}
.transactionHashContainer {
  display: flex;
}

.mlc_transaction-wrapper{
  @extend .transaction-wrapper
}
.mlc_transaction-details-body{
  @extend .transaction-details-body
}
@media (max-width: 480px) {
  .mlc_transaction-wrapper{
    padding: 16px;
  }
  .mlc_transaction-details-body{
    width: 100%;
    margin-bottom: 90px;
  }
}