@import styles/helpers

.loginSection
  display: flex
  height: 100%
  position: relative
  width: 100vw
  background: black
  overflow-y: auto
  justify-content: center
  align-items: center
  margin: 0 auto

.LoginImageRight
  display: none
  width: 50%
  position: relative
  overflow: hidden

  &:after, &:before
    content: ""
    position: absolute
    top: 0
    bottom: 0
    width: 5%
    z-index: 2

  &:before
    left: 0
    background-image: linear-gradient(to right, black, transparent)

  &:after
    right: 0
    background-image: linear-gradient(to left, black, transparent)


.loginContainer
  width: 100%
  padding: 0 32px 0 48px
  height: 100dvh
  overflow-y: auto
  display: flex
  flex-direction: column

.wrapper
  overflow-y: auto
  overflow-y: auto
  flex: 1 1

.inner_wrapper
  height: 714px
  max-width: 486px
  display: flex
  justify-content: center
  align-items: center
  flex-direction: column
  margin: 6px auto

  .country-code-container
    +dark
      background: #FFFFFF10 !important

.title_header
  display: grid
  gap: 4px !important

.title
  font-size: 28px
  font-weight: 700
  line-height: 40px
  color: $white

.sub_title
  font-weight: 500
  font-size: 16px
  line-height: 24px
  color: #AFB6C0

.login_info_disclaimer
  font-size: 12px
  font-weight: 400
  line-height: 20px
  color: #AFB6C0
  text-align: left
  padding-top: 12px


.disclaimer
  font-size: 10px
  font-weight: 400
  line-height: 14px
  color: #AFB6C0
  text-align: left

.finraLink
  color: #478FFB

@media ((min-device-width:1024px) and (max-device-width: 1512px))
  .loginContainer
    flex: 1
    height: 100dvh
  .inner_wrapper
    height: 662px
    max-width: 486px
  .LoginImageRight
    height: 100vh
    flex: 1
    display: grid
    place-items: center
  .LoginCards
    height: 100vh
    position: absolute
    z-index: 1
    width: 90%
    object-fit: cover

@media (min-device-width: 1512px)
  .loginSection
    display: flex
    justify-content: center
    align-items: center
    margin: 0 auto
    width: 1440px
    max-width: 1440dvw
  .loginContainer
    width: 100dvh
    height: 100dvh
    flex: 1
  .inner_wrapper
    height: 928px
    max-width: 486px
  .LoginImageRight
    height: 100vh
    // width: 50%
    flex: 1
    display: grid
    place-items: center
  .LoginCards
    height: 100vh
    position: absolute
    z-index: 1
    width: 90%
    object-fit: cover

.LoginBg
  filter: blur(48px)
  height: 100vh
  z-index: 0
  width: 100%

.header
  display: flex
  width: 100%
  align-items: center
  justify-content: space-between
  margin-top: 6%
  padding-bottom: 20px

.brand_logo
  height: 32px

.brand_fund_list
  height: 40px

.sub_title_small
  display: flex
  justify-content: flex-start
  align-items: flex-start
  font-weight: 400
  font-size: 14px
  line-height: 20px
  color: #AFB6C0
  text-align: left

.checkbox_container
  width:16px
  height: 16px
  padding: 4px
  border-radius: 4px
  border: 1px solid #FFFFFF1F
  background: #FFFFFF0F

.termsCondition
  color: #478FFB

.terms_and_privacy_wrapper
  display: flex
  justify-content: center
  text-align: center
  color: #4574F5
  gap: 12px
  margin: 24px 0px

.middle_element
  padding-left: 12px


.last_element
  text-align: right

  flex:1

.lists
  display: flex
  flex-direction: column
  gap: 16px

.list_item
  display: flex
  gap: 8px
  align-items: center
  background: rgba(51, 184, 122, 0.06)
  border-radius: 8px
  padding: 16px
  cursor: pointer
  color: $n13

  &:hover
    background: rgba(245, 175, 69, 0.06)
    color: $s5

.left_icon
  background: rgba(51, 184, 122, 0.12)
  border-radius: 24px
  width: 40px
  height: 40px
  display: grid
  place-items: center
  font-size: 24px


.right_icon
  font-size: 24px

.method_title
  font-weight: 600
  font-size: 16px
  line-height: 24px
  color: $n14
  flex: 1


.form_wrapper
  display: flex
  flex-direction: column
  gap: 24px
  margin-block: 16px 28px

.input_wrapper
  border: 1px solid $n12
  border-radius: 4px
  height: 52px
  display: flex
  align-items: center
  position: relative

  &:hover
    border-color: $n22

  &:focus-within
    border-color: $n28

  &.country-label
    max-width:200px

  &.css-1s2u09g-control
    border-width: none !important
    border-style: none !important

.input_field
  caret-color: $white
  padding: 12px 8px
  background: transparent
  color: #fff
  letter-spacing: 2px
  font-size: 16px
  width: 100%
  -webkit-box-shadow: 0 0 0 1000px #FFFFFF10 inset !important
//    -webkit-text-fill-color: #fff !important  TODO: anish, remove after testing

.input_field:-webkit-autofill
  -webkit-text-fill-color: #fff
  -webkit-box-shadow: 0 0 0px 1000px #000 inset
  transition: background-color 5000s ease-in-out 0s

.inputField_error
  border: 0.5px solid var(--color-error)

.inputField_error_background
  -webkit-box-shadow: 0 0 0 1000px hsl(0deg 88% 67% / 6%) inset !important
// -webkit-box-shadow: none !important
// background-color: hsl(0deg 88% 67% / 6%)


.login_btn
    background: #0C87FD 
    border-radius: 4px
    width: 100%
    height: 48px
    font-size: 16px
    color: #fff
    font-weight: 500
    transition: all 240ms ease-in-out
    &:hover
      color: #fff
      filter: brightness(0.8)

.login_btn_disabled
  background: $n19
  border-radius: 4px
  width: 100%
  height: 52px
  font-size: 14px
  color: $n16
  font-weight: 500
  cursor: not-allowed

.login_btn_loading
  background-color: #0C87FD
  height: 48px

.back_btn
  background: gray
  border-radius: 4px
  width: 100%
  height: 52px
  font-size: 14px
  color: #fff
  font-weight: 500

.footer_text
  font-weight: 400
  font-size: 14px
  line-height: 20px
  color: $n11
  cursor: pointer

.footer_highlighter
  font-weight: 600
  +dark
    color: $white


.input_countryCode
  font-weight: 500
  font-size: 16px
  margin-bottom: 2px


.footer
  display: flex
  padding-block: 16px
  color: $n16
  font-size: 16px
  font-weight: 600
  justify-content: center
  border-top: 1px solid $n19
  margin-top: 16px

.footer_link
  text-decoration: underline
  text-underline-offset: 4px
  cursor: pointer


.signin_inputError
  font-size: 16px
  font-weight: 500
  line-height: 24px
  color: #AFB6C0
  display: flex
  align-items: center
  gap: 4px

  &__icon
    color: #F26969
    font-size: 20px

.SignInInputError
  margin-top: 16px

.alert
  display: flex
  align-items: flex-start
  gap: 4px
  background-color: #1C1D1F
  border: 1px solid #FFFFFF1F
  border-left: 2px solid #F26969
  border-radius: 8px
  padding: 8px 12px
  max-width: 500px
  color: #ffffff
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2)
  margin: 20px 0px 0px 0px


.agreement
  font-size: 16px
  font-weight: 600
  line-height: 24px

  &__text
    color: #AFB6C0

.alert-content p
  font-size: 14px
  color: #e0e0e0
  margin: 0
  line-height: 1.4

.signInCrossIcon
  color: #F26969
  font-size: 20px
  line-height: 24px

// only for MLC css 
@media ((min-device-width: 480px))
.mlc_loginSection
    @extend .loginSection

.mlc_loginContainer
    @extend .loginContainer

.mlc_inner_wrapper
    @extend .inner_wrapper
    
@media ((max-device-width: 480px))
    .mlc_loginContainer
        padding: 24px 16px 0 16px
    .mlc_inner_wrapper
        height: 548px
    .mlc_loginSection
        margin: 0 !important


.loginModalSection
  background: transparent
  width: 100%

  .loginContainer
    height: auto
    padding: 0

  .inner_wrapper
    height: auto
    margin-block: 20px
    max-width: 100%
    padding-inline: 10px

  .title_header
    width: 100%

  .form_wrapper
    width: 480px
    margin-top: 28px
    +s
      width: 100%
  .title
    color: black
    +dark
      color: $white

  .sub_title
    color: $n4
    +dark
      color: #AFB6C0

  .input_field
    caret-color: black
    color: black
    &:-webkit-autofill
      -webkit-text-fill-color: black
    +dark
      caret-color: $white
      color: #fff
      &:-webkit-autofill
        -webkit-text-fill-color: #fff

  .sub_title_small
    color: $n4
    +dark
      color: #AFB6C0

  .alert
    background-color: $n7
    border-color: $n6
    color: black
    border-left-color: #F26969
    +dark
      background-color: #1C1D1F
      border-color: #FFFFFF1F
      color: #ffffff
      border-left-color: #F26969

  .agreement__text
    color: $n4
    +dark
      color: #AFB6C0
