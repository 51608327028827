@import styles/helpers
.MonthlyStatementContainer
    display: flex
    flex-direction: column
    max-width: 800px
    margin: 0 auto
    height: 60vh

.monthRow
    display: flex
    justify-content: space-between
    align-items: center
    padding: 12px 16px
    border-bottom: 1px solid $n81
    +dark
        border-bottom: 1px solid $n35

.monthDesc
    display: flex
    flex-direction: column
    gap: 4px
.monthHeader
    font-weight: bold
    font-weight: 600
    font-size: 16px
    line-height: 24px
    font-size: 16px
    width: 150px
    +dark
        color: $n64

.dateRange
    font-size: 14px
    flex-grow: 1
    font-weight: 500
    font-size: 14px
    line-height: 20px
    +dark
        color: $n9

.actions
    display: flex
    gap: 8px

.viewButton, .downloadButton
    width: 96px
    height: 32px
    border-radius: 4px
    cursor: pointer
    font-weight: 500
    font-size: 12px
    line-height: 16px

.downloadButton
    background-color: $n74
    color: white

.viewButton
    border: 1px solid $n74
    color: $n74
    display: flex
    align-items: center
    justify-content: center
    gap: 4px
    i
        font-size: 14px
        color: $n74
.emptyStateMyDocumentContainer
    display: flex
    flex-direction: column
    max-width: 800px
    margin: 0 auto
    justify-content: center
    align-items: center
    text-align: center
    height: 60vh

.emptyStateMyDocumentContainer__title
    font-weight: 500
    font-size: 16px
    line-height: 24px
    margin-top: 8px
