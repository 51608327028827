@import "../../../../styles/helper";

.bank-detail-card {
  display: flex;
  align-items: center;
  padding: 16px;
  background-color: $white;
  border: 1px solid $n6;
  flex: 1 1 100%;
  border-radius: 8px;
  gap: 16px;
  height: 88px;
  cursor: pointer;

  @include dark {
    background-color: $n19;
    border-color: $n12;
  }

  img {
    width: 30px;
    height: 30px;
    background-color: $n19;
    @include dark {
      color: $n7;
    }
  }

  .bank-detail-card-info {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .info-header {
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      text-transform: capitalize;
    }

    .info-description {
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      color: $n28;
      text-transform: capitalize;
    }
  }
}

.mlc_bank-detail-card{
  @extend .bank-detail-card
}

@media (max-width: 480px) {
  .mlc_bank-detail-card {
    padding: 8px;
    height: 58px;
    border-radius: 4px;
    img{
      height: 24px;
      width: 24px;
    }
  }
}